"use strict";
const scrollToTopButton = document.getElementById('mainStickyButtons_scrollToTop');
window.onscroll = () => { if (scrollToTopButton)
    onScroll(); };
// При скролле страницы показываем или скрываем кнопку скролла
const onScroll = () => {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        scrollToTopButton?.classList.replace('hidden', 'flex');
    }
    else
        scrollToTopButton?.classList.replace('flex', 'hidden');
};
// При клике на кнопку скролл вверх
scrollToTopButton?.addEventListener('click', () => globalThis.scrollTo({ top: 0, behavior: 'smooth' }));
